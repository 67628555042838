import { PandaTsCountries } from '../libs/countries'

export default {
  general: {
    and: 'and',
    or: 'or',
    sides: {
      front: 'front',
      back: 'back',
    },
  },
  $countries: Object.assign({}, ...PandaTsCountries.map((c) => ({ [c.alpha2]: c.name }))),
  countries: Object.assign({}, ...PandaTsCountries.map((c) => ({ [c.alpha2]: c.name }))),
  meta: {
    title: '{brand} Verification Center',
    description:
      "Verify your {brand} Trading account quickly and easily online with {brand}'s Verification Center. Get started now!",
  },
  buttons: {
    livechat: 'Support',
    chat: 'Help',
    logout: 'Log Out',
    ok: 'OK',
    upload: 'Upload',
    resume: 'Resume',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    browse: 'Browse',
    addBack: 'Add Back',
  },
  product: 'Verification Center',
  login: {
    meta: {
      title: 'Log in to your {brand} Trading Account',
      description: 'Log in to your {brand} trading account to verify your identity.',
    },
    prompt: {
      regular: 'Please enter your {brand} {bolded}',
      bolded: 'trading account credentials',
    },
    actions: {
      forgot: {
        label: 'Forgot your password? {bolded}',
        bolded: 'Click here',
      },
    },
    feedback: {
      success: {
        title: 'Authentication Successful',
        text: 'Welcome, {name}',
      },
    },
  },
  forgot: {
    meta: {
      title: 'Retrieve your {brand} Trading Account Credentials',
      description: 'Forgot your {brand} trading account password? Reset it here.',
    },
    prompt: {
      regular: 'Please enter your {brand} {bolded} to retrieve your credentials',
      bolded: 'trading account email',
    },
    actions: {
      login: {
        label: 'Ready to log in? {bolded}',
        bolded: 'Click here',
      },
    },
    feedback: {
      success: {
        title: 'Request Submitted Successfully',
        text: 'If your email address is associated with a {brand} trading account, you will receive an email with instructions to reset your password.',
      },
    },
  },
  index: {
    meta: {
      title: 'Verify your {brand} trading account quickly and easily',
      description:
        'Most verifications take only a few minutes. Just upload the required documents and information in the following forms and our team of specialists will ensure that everything is in order.',
    },
    wizard: {
      title: '{brand} Verification Center',
      howItWorks: {
        title: 'How it works',
        content:
          'Verifying your iXPFX trading account is quick and easy - Most verifications take only a few minutes. Just upload the required documents and information in the following forms and our team of specialists will ensure that everything is in order.',
      },
      whatsNeeded: {
        title: "What's needed",
        p1: 'You will need to provide the following documents and information:',
        p2: 'Documents can only be uploaded in PDF, JPG or PNG format and must be less than 10MB in size.',
      },
      cta: 'Ready to start?',
      action: 'Start Verification',
    },
  },
  fourOhFour: {
    meta: {
      title: 'Page Not Found',
      description: 'The page you are looking for could not be found.',
    },
    headline:
      "Sorry, the page you're looking for cannot be found. Please check the URL or return to the homepage for further assistance.",
  },
  fields: {
    email: 'Email Address',
    password: 'Password',
    otp: 'One-Time Password',
    message: 'Message',
    rsaId: 'National ID Number',
    docType: 'Type of Document',
    docHasBack: 'Two-Sided Document',
    docFile: 'Your Document',
    docFront: 'Front Side',
    validatorDocFront: 'The Front Side of your document',
    docBack: 'Back Side',
    validatorDocBack: 'The Back Side of your document',
    singleSideEvidence: 'Your {type}',
    singleSideEvidenceInSentence: 'your {type}',
    multiSideEvidence: 'The {side} side of your {type}',
    multiSideEvidenceInSentence: 'the {side} side of your {type}',
  },
  placeholders: {
    enter: 'Please enter your {label}',
    choose: 'Please choose a {label}',
    chooseYour: 'Please choose your {label}',
  },
  hints: {
    upload: 'You can upload a {types} with a maximum size of {size}',
    evidenceMustBeSubmitted: 'You must choose the type of document before you can upload files.',
    push: {
      title: 'Would you like to enable notifications?',
      titleShort: 'Enable Notifications?',
      message: 'Recieve updates on your {brand} Account Verification',
      messageShort: 'Recieve Verification Updates',
    },
    instructions: {
      textOnPhotosReadable: 'Any text on the photos must be easily readable.',
      noReflectionsObscureDocument:
        'There shouldn’t be any reflections that could obscure parts of the document.',
      allCornersClearlyVisible: 'All four corners of the document need to be clearly visible.',
      noBlurryPhotos: 'The photos cannot be blurry.',
      wellLitEnvironment:
        'Make sure the photos are taken in a well-lit environment, so all the information on them is clearly visible.',
      noObscuringBodyParts:
        'Make sure there are no body parts or objects obscuring parts of the photos.',
      notOlderThan_3Months: 'The Proof of Residence cannot be older than three (3) months.',
      shouldIncludeNameAddress:
        'The Proof of Residence needs to include your name and your address.',
    },
  },
  actions: {
    login: 'Log In',
    submit: 'Submit',
    back: 'Back',
    continue: 'Continue',
    save: 'Save',
    home: 'Home',
    replace: 'Replace',
  },
  validation: {
    bad: 'The value is not a valid {label}',
    notAFile: 'Please select {label}',
    tooManyFiles: 'Please select only 1 file',
    fileTooLarge: 'The file you have selected is too large',
    invalidFileType: 'The file you have selected is not an acceptable file type',
    fileTypeNotAccepted: 'The file you have selected is not an acceptable file type',
    invalid: 'Please enter your {label}',
    required: 'Please enter your {label}',
    requiredSelection: 'Please choose a {label}',
    requiredUpload: 'Please select {label}',
    email: 'Please enter a valid email address',
    min: 'Your {label} must be at least {min} characters long',
    characters: 'The {label} you have input contains invalid characters',
    invalidRsaId: 'Please enter a valid {label}',
    alternatives: {
      all: 'The value did not match all of the criteria.',
      any: 'No alternative was found to test against the input due to try criteria.',
      match:
        'No alternative matched the input due to specific matching rules for at least one of the alternatives.',
      one: 'The value matched more than one alternative schema.',
      types: 'The provided input did not match any of the allowed types.',
    },
    any: {
      custom: 'Please enter a valid {label}',
      default: 'Please contact support',
      failover: 'Please contact support',
      invalid: 'The value matched a value listed in the invalid values.',
      only: "Only some values were allowed, the input didn't match any of them.",
      ref: 'The input is not valid.',
      required: "A required value wasn't present.",
      unknown: "A value was present while it wasn't expected.",
    },
    array: {
      base: '{label} is required',
      excludes: 'The array contains a value that is part of the exclusion list.',
      includesRequiredBoth:
        'Some values were expected to be present in the array and are missing. This error happens when we have a mix of labeled and unlabeled schemas.',
      includesRequiredKnowns:
        'Some values were expected to be present in the array and are missing. This error happens when we only have labeled schemas.',
      includesRequiredUnknowns:
        'Some values were expected to be present in the array and are missing. This error happens when we only have unlabeled schemas.',
      includes: "The value didn't match any of the allowed types for that array.",
      length: 'The array is not of the expected length.',
      max: 'The array has more elements than the maximum allowed.',
      min: 'The array has less elements than the minimum allowed.',
      orderedLength: 'Given an array.ordered(), that array has more elements than it should.',
      sort: {
        base: 'The array did not match the required sort order.',
        mismatching: 'Failed sorting the array due to mismatching item types.',
        unsupported: 'Failed sorting the array due to unsupported item types.',
      },
      sparse: "An undefined value was found in an array that shouldn't be sparse.",
      unique: 'A duplicate value was found in an array.',
      hasKnown:
        'The schema on an array.has() was not found in the array. This error happens when the schema is labeled.',
      hasUnknown:
        'The schema on an array.has() was not found in the array. This error happens when the schema is unlabeled.',
    },
    binary: {
      base: 'The value is either not a Buffer or could not be cast to a Buffer from a string.',
      length: 'The buffer was not of the specified length.',
      max: 'The buffer contains more bytes than expected.',
      min: 'The buffer contains less bytes than expected.',
    },
    boolean: {
      base: '{label} is required',
    },
    date: {
      base: '{label} is required',
      format: 'The date does not match the required format.',
      greater: 'The date is over the limit that you set.',
      less: 'The date is under the limit that you set.',
      max: 'The date is over or equal to the limit that you set.',
      min: 'The date is under or equal to the limit that you set.',
      strict: 'Occurs when the input is not a Date type and convert is disabled.',
    },
    function: {
      arity: "The number of arguments for the function doesn't match the required number.",
      class: 'The input is not a JavaScript class.',
      maxArity: 'The number of arguments for the function is over the required number.',
      minArity: 'The number of arguments for the function is under the required number.',
    },
    number: {
      base: 'The value is not a number or could not be cast to a number.',
      greater: 'The number is lower or equal to the limit that you set.',
      infinity: 'The number is Infinity or -Infinity.',
      integer: 'The number is not an integer.',
      less: 'The number is greater or equal to the limit that you set.',
      max: 'The number is greater than the limit that you set.',
      min: 'The number is lower than the limit that you set.',
      multiple: 'The number is not a multiple of the required number.',
      negative: 'The number is positive.',
      port: 'The number is not a valid port.',
      positive: 'The number is negative.',
      precision: 'The number has more decimal places than expected.',
      ref: 'The number is not a valid reference.',
      safe: 'The number is not a safe integer.',
      sign: 'The number is not of the required sign.',
      unsafe: 'The number is a safe integer.',
    },
    object: {
      allowUnknown: 'An unknown key was found in the object.',
      and: 'Some keys are missing in the object.',
      assert: 'The object did not pass the assertion test.',
      base: '{label} is required',
      instance: 'The object is not an instance of the required type.',
      length: 'The object does not have the required number of keys.',
      max: 'The object has more keys than the maximum allowed.',
      min: 'The object has less keys than the minimum allowed.',
      missing: 'Some keys are missing in the object.',
      nand: 'Some keys are present in the object.',
      oxor: 'Some keys are missing in the object.',
      pattern: 'The object has a key that did not match the required pattern.',
      ref: 'The object is not a valid reference.',
      regex: {
        base: 'The object has a key that did not match the required pattern.',
        name: 'The object has a key that did not match the required pattern.',
        invert: {
          base: 'The string matches the excluded pattern.',
          name: 'The string matches the excluded pattern.',
        },
      },
      rename: {
        multiple: 'The object has multiple keys when only one is allowed.',
        override: 'The object has a key that is not allowed to be overridden.',
      },
      schema: 'The object has a key that did not match the required schema.',
      unknown: 'An unknown key was found in the object.',
      with: 'Some keys are missing in the object.',
      without: 'Some keys are present in the object.',
      xor: 'Some keys are missing in the object.',
    },
    string: {
      alphanum: 'The string contains characters that are not alphanumeric.',
      base: '{label} is required',
      base64: 'The string is not a valid base64 encoded string.',
      creditCard: 'The string is not a valid credit card number.',
      dataUri: 'The string is not a valid data URI.',
      domain: 'The string is not a valid domain.',
      email: 'The string is not a valid email.',
      empty: 'The string is empty.',
      guid: 'The string is not a valid GUID.',
      hex: 'The string is not a valid hexadecimal string.',
      hexAlign: 'The string is not a valid hexadecimal string.',
      hostname: 'The string is not a valid hostname.',
      ip: 'The string is not a valid IP address.',
      ipVersion: 'The string is not a valid IP address of the required version.',
      isoDate: 'The string is not a valid ISO date.',
      isoDuration: 'The string is not a valid ISO duration.',
      length: 'The string is not of the required length.',
      lowercase: 'The string contains uppercase characters.',
      max: 'The string is longer than the maximum allowed length.',
      min: 'The string is shorter than the minimum allowed length.',
      normalize: 'The string is not in the required Unicode normalization form.',
      pattern: {
        base: 'The string does not match the required pattern.',
        name: 'The string does not match the required pattern.',
        invert: {
          base: 'The string matches the excluded pattern.',
          name: 'The string matches the excluded pattern.',
        },
      },
      ref: 'The string is not a valid reference.',
      regex: {
        base: 'The string does not match the required pattern.',
        name: 'The string does not match the required pattern.',
        invert: {
          base: 'The string matches the excluded pattern.',
          name: 'The string matches the excluded pattern.',
        },
      },
      token: 'The string is not a valid token.',
      trim: 'The string contains leading or trailing whitespaces.',
      uri: {
        base: 'The string is not a valid URI.',
        customScheme: 'The string is not a valid URI.',
        relativeOnly: 'The string is not a valid relative URI.',
      },
      uppercase: 'The string contains lowercase characters.',
    },
    symbol: {
      base: '{label} is required',
      map: 'The value is not a valid Symbol.',
    },
  },
  errors: {
    unknown: 'An unknown error has occured',
    formValidationFailed: 'There was an error processing your form. Please contact support',
    invalidLoginOrPassword:
      'Invalid Email or Password. Please check your credentials and try again',
    system: {
      E_ROUTE_NOT_FOUND: 'The requested endpoint could not be found',
      failed: 'An error occured attempting this request. Please contact support for assistance',
      unavailable: {
        loginService:
          'The login service is currently unavailable. Please try again in a few minutes.',
      },
      unknown: 'An unknown error has occured',
    },
    document: {
      tooLarge: {
        title: 'File Too Large',
        body: 'The file you have selected is too large ({current}). Please select a file smaller than {size}',
      },
      tooMany: {
        title: 'Too Many Files',
        body: 'This field only accepts a single file. Please drop only one file and try again.',
      },
      invalidType: {
        title: 'Invalid File Type',
        body: 'We can only accept {accepted} files. Please select another file and try again.',
      },
      dropOccupied: {
        title: 'Replace File?',
        body: 'You have already selected a file. Do you want to replace it?',
      },
    },
    camera: {
      open: {
        failed:
          'An error has occured while trying to open your camera. Please use a different method to upload your document.',
      },
      change: {
        failed: 'An error has occured while trying to switch cameras.',
      },
    },
    notLoggedIn: 'You are not logged in. Please log in and try again.',
    notImplemented: 'This feature is not yet implemented. Please try again later.',
    uploadFailed: {
      title: 'Upload Failed',
      text: 'Please try again. If you continue to experience issues, please contact support.',
    },
    evidenceNotFound: 'There was a problem processing your request. Please try again later.',
    sumsub: {
      token: {
        title: 'Failed to load Verification Center',
        text: 'There was an error loading the verification center. Please try again or contact support.',
      },
    },
    provider: {
      wrong: {
        title: 'Refreshing Verification Center',
        text: 'Please wait while the portal is updated.',
      },
    },
  },
  successes: {
    questionnaire: {
      submitted: 'Your questionnaire has been submitted successfully',
    },
    push: {
      enabled: {
        title: 'Push Notifications Enabled',
        text: 'To disable push notifications, please review your browser settings',
      },
    },
  },
  warnings: {
    push: {
      rejected: {
        title: 'Push Notifications Disabled',
        text: 'To enable push notifications, please review your browser settings',
      },
    },
    evidence: {
      documentPreviousUploaded: {
        title: 'Document Uploaded',
        text: 'You have already uploaded {type}.',
      },
    },
  },
  evidences: {
    rsaId: {
      title: 'National ID Number',
      explanation: 'Your Government Issued National ID Number',
      meta: {
        title: 'Submit your National ID Number',
        description:
          'Submit your National ID Number for verification to {brand} quickly and easily online.',
      },
    },
    poi: {
      title: 'Proof of Identity',
      explanation:
        'A photo of a government issued photo ID such as a passport or driver’s license.',
      meta: {
        title: 'Submit your Proof of Identity',
        description:
          'Submit your Proof of Identity for verification to {brand} quickly and easily online.',
      },
    },
    por: {
      title: 'Proof of Residence',
      explanation:
        'A document with addressed to you at your current address such as a utility bill or bank statement.',
      meta: {
        title: 'Submit your Proof of Residence',
        description:
          'Submit your Proof of Residence for verification to {brand} quickly and easily online.',
      },
    },
    questionnaire: {
      title: 'Questionnaire',
      explanation:
        'A short questionnaire to help us understand your trading experience and investment goals.',
      meta: {
        title: 'Answer the Verification Questionnaire',
        description:
          'Answer the Verification Questionnaire to complete your {brand} trading account verification quickly and easily online.',
      },
    },
    unknown: {
      title: 'Submission',
    },
  },
  summary: {
    statuses: {
      account: 'Account Verification Status',
      canTrade: 'Trading is currently enabled on your {brand} account',
    },
    prompt:
      'To complete your {brand} account verification or to track your submission status, please use the navigation menu.',
    approved: 'Your {evidence} has been approved by {brand}.',
  },
  statuses: {
    verification: {
      unknown: 'Unknown',
      new: 'Newly Submitted',
      none: 'Unverified',
      reviewing: 'Under Review',
      approved: 'Approved',
      incomplete: 'Incomplete',
      rejected: 'Rejected',
      loading: 'Loading...',
    },
    kyc: {
      complete: 'Complete',
      incomplete: 'Incomplete',
    },
  },
  documents: {
    types: {
      'id': 'Government Issued Photo ID',
      'passport': 'Passport',
      'license': "Driver's License",
      'visa': 'Government Issued Visa',
      'green_bar_coded_id': 'Green Barcoded ID',
      'smart_card_sa_id': 'Smart Identity Card',
      'utility': 'Utility Bill',
      'bank-statement': 'Bank Statement',
      'bank-confirmation': 'Bank Confirmation Letter',
      'lease': 'Lease Agreement',
      'insurance': 'Car / Health / Property Insurance',
      'affidavit': 'Affidavit',
      'bank_statement': 'Bank Statement',
      'cellular_or_telephone_invoice': 'Cellular or Telephone Invoice',
      'government_issued_document': 'Government Issued Document',
      'investment_statement': 'Investment Statement',
      'it3_tax_certificate': 'IT3 Tax Certificate',
      'lease_or_rental_agreement': 'Lease or Rental Agreement',
      'letter_from_municipal_representative': 'Letter From Municipal Representative',
      'levy_statement': 'Levy Statement',
      'mortgage_statement': 'Mortgage Statement',
      'municipal_rates_and_taxes': 'Municipal Rates And Taxes',
      'official_sars_document': 'Official SARS Document',
      'retail_or_credit_account_statement': 'Retail or Credit Account Statement',
      'short_or_long_term_insurance_policy': 'Short or Long Term Insurance Policy',
      'television_license': 'Television License',
      'utility_bill': 'Utility Bill',
    },
  },
  questionnaire: {
    birthday: {
      question: 'When were you born?',
      label: 'Birthday',
    },
    occupation: {
      question: 'What is your occupation?',
      label: 'Occupation',
    },
    sourceOfFunds: {
      question: 'What is the source of your funds?',
      label: 'Source of Funds',
    },
    options: {
      occupation: {
        accommodationTourism: 'Accommodation Tourism',
        activitiesOfHouseholds: 'Household Domestic Work',
        administrativeAndSupportServiceActivities: 'Administrative & Support Service Activities',
        agricultureFarmingForestryFishing: 'Agriculture Farming Forestry Fishing',
        artsEntertainmentAndRecreation: 'Arts Entertainment & Recreation',
        constructionRelatedActivities: 'Construction Related Activities',
        dormantAndOrPassiveIncome: 'Dormant / Passive Income',
        education: 'Education',
        energyAndPowerGeneration: 'Energy & Power Generation',
        financialAndInsuranceRegulated: 'Financial & Insurance Regulated',
        financialAndInsuranceNotRegulated: 'Financial & Insurance Not Regulated',
        humanHealthAndSocialWorkActivities: 'Human Health & Social Work Activities',
        huntingRelatedServices: 'Hunting Related Services',
        importExportAgency: 'Import Export Agency',
        informationCommunicationTechnology: 'Information Communication Technology',
        manufacturingChemicalsPharmaceuticals: 'Manufacturing - Chemicals Pharmaceuticals',
        manufacturingDefenceAndOrAmmunitions: 'Manufacturing - Defence',
        manufacturingOther: 'Manufacturing - Other',
        manufacturingTobaccoHemp: 'Manufacturing - Tobacco Hemp',
        miningQuarryingRelatedServices: 'Mining & Quarrying Related Services',
        nonProfitNgoCharity: 'Non Profit NGO Charity',
        other: 'Other',
        politicalActivities: 'Political Activities',
        professionalScientificAndTechnicalActivities:
          'Professional Scientific & Technical Activities',
        publicStateOwnedEntitiesSoe: 'Public State Owned Entities (SOE)',
        realEstateActivities: 'Real Estate Activities',
        religiousActivities: 'Religious Activities',
        transportationAndStorage: 'Transportation And Storage',
        wasteManagement: 'Waste Management',
        wholesaleRetailRepairTradeGamblingBetting:
          'Wholesale - Retail Repair Trade Gambling Betting',
        wholesaleRetailRepairTradeRestaurantsTakeaways:
          'Wholesale - Retail Repair Trade Restaurants Takeaways',
        wholesaleRetailRepairTradeTobaccoHemp: 'Wholesale - Retail Repair Trade Tobacco Hemp',
        wholesaleRetailRepairTradeJewelleryMetalsStones:
          'Wholesale - Retail Repair Trade Jewellery Metals Stones',
        wholesaleRetailRepairTradeMotorVehicles: 'Wholesale - Retail Repair Trade Motor Vehicles',
        wholesaleRetailRepairTradeOther: 'Wholesale - Retail Repair Trade Other',
        wholesaleRetailRepairTradePharmaceuticals:
          'Wholesale - Retail Repair Trade Pharmaceuticals',
        wholesaleRetailRepairTradeScrapRecycledMaterials:
          'Wholesale - Retail Repair Trade Scrap Recycled Materials',
      },
      sourceOfFunds: {
        allowances: 'Allowances',
        bursary: 'Bursary',
        companyProfits: 'Company Profits',
        companySale: 'Company Sale',
        courtOrder: 'Court Order',
        deceasedEstate: 'Deceased Estate',
        divorceSettlement: 'Divorce Settlement',
        giftDonation: 'Gift / Donation',
        incomeFromEmployment: 'Income from Employment',
        incomeFromPreviousEmployment: 'Income from Previous Employment',
        inheritance: 'Inheritance',
        loanFinancialInstitution: 'Loan (Financial Institution)',
        loanOther: 'Loan (Other)',
        lotteryWinningsGambling: 'Lottery / Winnings / Gambling',
        maintenance: 'Maintenance',
        maturingInvestments: 'Maturing Investments',
        pension: 'Pension',
        rentalIncome: 'Rental Income',
        selfEmployed: 'Self Employed',
        saleOfAsset: 'Sale of Asset',
        saleOfShares: 'Sale of Shares',
        savingsInvestmentsDividend: 'Savings / Investments / Dividend',
        trustDistribution: 'Trust Distribution',
      },
    },
  },
}
